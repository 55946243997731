<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Campos de {{layout.nombre}}</v-card-title>
      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        ><CIcon name="cil-user" /> Nuevo</CButton
      >

      <v-card-text>
        <filters
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          
          <template #llavePrimaria="data">
            <td style="width:16px">
              <img v-if="data.item.llavePrimaria" src="key.png" style="width: 16px;" v-c-tooltip.hover.click="'Es llave primaria'" />
            </td>
          </template>

          <template #activo="data">
            <td>
              <CBadge :color="data.item.activo?'success':'secondary'">
                {{ data.item.activo==1?'Habilitado':'Inhabilitado' }}
              </CBadge>
            </td>
          </template>
          <template #acciones="data">
            <td>
              <CLink
                v-if="!bloquearEdicion()"
                href="#"
                @click="editar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Editar'"
              >
                <CIcon name="cil-pencil" />
              </CLink>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
       <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="goBack">Volver</CButton>&nbsp;
      </v-card-actions>
    </v-card>
    <br><br>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import CamposService from "@/services/campo.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Users",
  data() {
    return {
      layout: {
        nombre: undefined
      },
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "llavePrimaria", label: "" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "orden", label: "Orden" },
        { key: "activo", label: "Estado" },
        { key: "acciones", label: "Acciones" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre", label: "Nombre" },
        {
          value: "activo",
          label: "Estado",
          options: [
            { id: 1, name: "Habilitado" },
            { id: 0, name: "Inhabilitado" },
          ],
        },
      ],
      activePage: 1,
      loading: false,
      message: "",
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de variables"))
      this.$router.push("/dashboard")

    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda)
    })

    if (localStorage.getItem("datos_layout")) {
      this.layout = JSON.parse(localStorage.getItem("datos_layout"))
    }
    this.fetchItems('')
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      console.log('fetch layouts campos')
      console.log(this.layout)
      CamposService.getItems(this.layout.id, cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    crear() {
      localStorage.removeItem("datos_campo");
      this.$router.push({ path: `campos/editar/` });
    },
    editar(item) {
      localStorage.setItem("datos_campo", JSON.stringify(item));
      this.$router.push({ path: `campos/editar/` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/layouts" });
    },
    bloquearEdicion() {
      return this.layout.nombre == 'REPOSITORIO FOTOGRAFICO'
    }
  },
};
</script>
